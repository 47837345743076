<template>
    <div class="p-fluid">
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <div v-if="distlength == 1">
                    <b>DISTRIBUTOR : {{distLabel}}</b>
                </div>
                <div v-else>
                    <label>Distributor</label>
                        <Dropdown id="dist_code" dataKey="dist_code" v-model="forms.dist_code" :loading="loadingDropdownDistributor"
                            :options="dataDropdownDistributor" :class="{ 'p-invalid': errorAdd.dist_code }" class="mt-2"
                            optionLabel="label" optionValue="dist_code" placeholder="Pilih Distributor"
                            :filter="true" :showClear="true" @filter="searchDropdownDistributor($event, 'add')"
                            @change="changeSalesmanOutlet()"
                        />
                        <small class="p-invalid" style="color: red" v-if="errorAdd.dist_code">{{
                            errorAdd.dist_code[0]
                    }}</small>
                </div>
            </div>
        </div>
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Salesman</label>
                <Dropdown id="userid" dataKey="userid" v-model="forms.salesman_id" :loading="loadingDropdownSalesman"
                    :options="dataDropdownSalesman" :class="{ 'p-invalid': errorAdd.salesman_id }"
                    optionLabel="label" optionValue="userid" placeholder="Pilih Salesman"
                    :filter="true" :showClear="true" @filter="searchDropdownSalesman($event, 'add')"
                />
                <small class="p-invalid" style="color: red" v-if="errorAdd.salesman_id">{{
                    errorAdd.salesman_id[0]
                }}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Hari</label>
                <Dropdown v-model="forms.hari" :options="dataDropdownHari" optionLabel="name" optionValue="code" placeholder="Pilih Hari" :showClear="true" :class="{ 'p-invalid': errorAdd.hari }"/>
                <small class="p-invalid" style="color: red" v-if="errorAdd.hari" >{{ errorAdd.hari[0] }}</small>
            </div>
        </div>
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Outlet</label>
                <!-- <div class="field-checkbox mt-2">
                    <Checkbox id="isAllOutlet" v-model="checked" :binary="true" :disabled="forms.area_id == null" @change="this.forms.outlet_id = [];"/>
                    <label for="isAllOutlet">Centang jika pilih semua Outlet</label>
                </div> -->
                <Listbox ref="doutlet" v-model="forms.outlet_id" dataKey="cust_id" :options="dataDropdownOutlet" :class="{ 'p-invalid': errorAdd.outlet_id }" :multiple="true" :filter="true" optionLabel="label"  listStyle="max-height:250px" style="width:15rem" @filter="searchDropdownOutlet($event, 'add')">
                    <template #option="slotProps">
                        <div>{{slotProps.option.label}}</div>
                    </template>
                </Listbox>
                <small class="p-invalid" style="color: red" v-if="errorAdd.outlet_id">{{
                    errorAdd.outlet_id[0]
                }}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-9" v-if="convertMultiSelectOutlet(forms.outlet_id).length != 0">
                <div class="card" style="height: calc(60vh - 143px)">
                    <DataTable :value="forms.outlet_id" :scrollable="true" scrollHeight="flex">
                        <Column field="label" header="Outlet">
                            <template #body="slotProps">
                                {{ slotProps.data.label }}
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
    <Button :loading="loading" label="Simpan" icon="pi pi-save" class="p-button-primary" @click="saveNew" />
</template>

<script>

import { mapGetters } from "vuex";

export default {
    emits: ["submit"],
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownDistributor: false,
            loadingDropdownSalesman: false,
            loadingDropdownOutlet: false,

            // dataDropdown
            dataDropdownHari: [
                {name: 'SENIN', code: 'SENIN'},
                {name: 'SELASA', code: 'SELASA'},
                {name: 'RABU', code: 'RABU'},
                {name: 'KAMIS', code: 'KAMIS'},
                {name: 'JUMAT', code: 'JUMAT'},
                {name: 'SABTU', code: 'SABTU'},
                {name: 'MINGGU', code: 'MINGGU'},
            ],
            dataDropdownDistributor: null,
            dataDropdownSalesman: null,
            dataDropdownOutlet: null,

            // addNew
            forms: {
                dist_code: null,
                salesman_id: null,
                outlet_id: [],
                hari: null,
            },
            distDataUser : null,
        }
    },
    created(){
        this.searchDropdownDistributor('');
    },
    mounted() {
        this.$store.commit('setErrorAdd', {});
        setTimeout(() => {
            this.distDataUser = this.dataDropdownDistributor;
            if(this.distlength == 1){
                this.searchDropdownSalesman('');
                this.searchDropdownOutlet('');
            }
        }, 700);
    },
    computed:{
        ...mapGetters(['errorAdd']),
		...mapGetters("auth", ["user"]),
        distLabel(){
            return this.distlength == 1 ? this.distDataUser[0].label : null;
        },
        distlength(){
            // return this.user?.dist_code;
            return this.distDataUser?.length;
        }
    },
    methods: {
        convertMultiSelectOutlet(data) {
            if(data){
                return data.map((item) => {
                    return item.cust_id;
                });
            }else{
                return [];
            }
        },
        // DROPDOWN
        searchDropdownDistributor(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.ddistributor.filterValue = valueEdit;
                }

                if (purpose == "add") {
                    this.loadingDropdownDistributor = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-distributor-v2',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                    .then(res => {

                        if (purpose == "add") {
                            this.dataDropdownDistributor = res.data.data;
                            this.loadingDropdownDistributor = false;
                        } else if (purpose == null) {
                            this.dataDropdownDistributor = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        changeSalesmanOutlet() {
            this.forms.salesman_id = null;
            this.dataDropdownSalesman = null;
            this.searchDropdownSalesman('');

            this.forms.outlet_id = null;
            this.dataDropdownOutlet = null;
            this.searchDropdownOutlet('');
        },
        searchDropdownSalesman(event, purpose, valueEdit) {
            setTimeout(() => {

                if (this.forms.dist_code || this.distlength == 1) {

                    if (valueEdit) {
                        this.$refs.dsalesman.filterValue = valueEdit;
                    }

                    if (purpose == "add") {
                        this.loadingDropdownSalesman = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/master-salesman',
                        params: {
                            "search": valueEdit ? valueEdit : event.value,
                            "distributor_code": this.distlength == 1 ? this.distDataUser[0].dist_code : this.forms.dist_code,
                        }
                    })
                    .then(res => {

                        if (purpose == "add") {
                            this.dataDropdownSalesman = res.data.data;
                            this.loadingDropdownSalesman = false;
                        } else if (purpose == null) {
                            this.dataDropdownSalesman = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                }

            }, 250);
        },
        searchDropdownOutlet(event, purpose, valueEdit) {
            setTimeout(() => {

                if (this.forms.dist_code || this.distlength == 1) {

                    if (valueEdit) {
                        this.$refs.doutlet.filterValue = valueEdit;
                    }

                    if (purpose == "add") {
                        this.loadingDropdownOutlet = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/master-outlet',
                        params: {
                            "search": valueEdit ? valueEdit : event.value,
                            "distributor_code": this.distlength == 1 ? this.distDataUser[0].dist_code : this.forms.dist_code,
                        }
                    })
                    .then(res => {

                        if (purpose == "add") {
                            this.dataDropdownOutlet = res.data.data;
                            this.loadingDropdownOutlet = false;
                        } else if (purpose == null) {
                            this.dataDropdownOutlet = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                }

            }, 250);
        },
        // ADDNEW
        saveNew() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/master-pjp/create',
                data: {
                    "salesman_id": this.forms.salesman_id,
                    "outlet_id": this.convertMultiSelectOutlet(this.forms.outlet_id),
                    "hari": this.forms.hari,
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Disimpan', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorAdd', {});
                    this.clearForms();
                    
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorAdd', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Disimpan', life: 3000,});
            });
        },
        clearForms() {
            this.forms.dist_code = null;
            this.forms.salesman_id = null;
            this.forms.outlet_id = [];
            this.forms.hari = null;

            if(this.distlength == 1){
                this.searchDropdownSalesman('');
                this.searchDropdownOutlet('');
            }else{
                this.dataDropdownOutlet = [];
                this.dataDropdownSalesman = [];
            }
        },
    }
}
</script>