<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Toast />
                <Error :errors="errors" />

                <Fieldset legend="Import" :toggleable="true" :collapsed="true" class="mb-3"
                    v-if="permission.includes('create')">
                    <Import @submit="getDataTable()" ref="import" />
                </Fieldset>

                <Fieldset legend="Tambah Baru" :toggleable="true" :collapsed="true" class="mb-3"
                    v-if="permission.includes('create')">
                    <Create @submit="getDataTable()" ref="create" />
                </Fieldset>

                <Fieldset legend="Filter" :toggleable="true" :collapsed="true" class="mb-3">
                    <div class="p-fluid">
                        <div class="formgrid grid">
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label for="dropdownRegion">Region</label>
                                <Dropdown v-model="filters.region_id" :loading="loadingDropdownRegion"
                                    :options="dataDropdownRegion" optionLabel="region_name" optionValue="region_id"
                                    placeholder="Pilih Region" :filter="true" :showClear="true"
                                    @filter="searchDropdownRegion($event, 'filter')"  @change="changeRegion('filter')"/>
                            </div>
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label for="dropdownDistributor">Distributor</label>
                                <Dropdown v-model="filters.dist_code" :loading="loadingDropdownDistributor"
                                    :options="dataDropdownDistributor" optionLabel="dist_name" optionValue="dist_code"
                                    placeholder="Pilih Distributor" :filter="true" :showClear="true"
                                    @filter="searchDropdownRegion($event, 'filter')" />
                            </div>
                        </div>
                    </div>
                    <Button :loading="loading" label="Filter" icon="pi pi-search" class="p-button-warning mr-2 my-1"
                        @click="getDataTable" />
                </Fieldset>

                <DataTable :value="dataTable" responsiveLayout="scroll" :loading="loading" dataKey="number"
                    @sort="onSort($event)" :resizableColumns="true" columnResizeMode="fit">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <div>
                                <Button :loading="loadingExcel" label="Export Excel" icon="pi pi-file-excel"
                                    class="p-button p-button-success mr-2 my-1 inline-block"
                                    @click="exportExcelCSV('xlsx')" />
                                <Button :loading="loadingCsv" label="Export CSV" icon="pi pi-file-excel"
                                    class="p-button p-button-success mr-2 my-1 inline-block"
                                    @click="exportExcelCSV('csv')" />
                            </div>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="search" placeholder="Cari..." @keyup.enter="getDataTable" />
                            </span>
                        </div>
                    </template>
                    <Column field="number" header="No." :style="{ width: '20px' }">
                        <template #body="slotProps">
                            {{ slotProps.data.number }}
                        </template>
                    </Column>
                    <Column field="region_name" header="Region">
                        <template #body="slotProps">
                            {{ slotProps.data.region_name }}
                        </template>
                    </Column>
                    <Column field="dist_code" header="Dist Code">
                        <template #body="slotProps">
                            {{ slotProps.data.dist_code }}
                        </template>
                    </Column>
                    <Column field="dist_name" header="Dist Name">
                        <template #body="slotProps">
                            {{ slotProps.data.dist_name }}
                        </template>
                    </Column>
                    <Column field="username" header="Salesman Code">
                        <template #body="slotProps">
                            {{ slotProps.data.username }}
                        </template>
                    </Column>
                    <Column field="nama" header="Salesman Name">
                        <template #body="slotProps">
                            {{ slotProps.data.nama }}
                        </template>
                    </Column>
                    <Column field="cust_code" header="Cust Code">
                        <template #body="slotProps">
                            {{ slotProps.data.cust_code }}
                        </template>
                    </Column>
                    <Column field="cust_name" header="Cust Name">
                        <template #body="slotProps">
                            {{ slotProps.data.cust_name }}
                        </template>
                    </Column>
                    <Column field="hari_kunjungan" header="Hari Kunjungan">
                        <template #body="slotProps">
                            {{ slotProps.data.hari_kunjungan }}
                        </template>
                    </Column>
                    <Column field="is_active" header="Status PJP">
                        <template #body="slotProps">
                            <Button icon="pi pi-check" class="p-button-rounded"
                                v-if="slotProps.data.is_active == 'y'" />
                            <Button icon="pi pi-times" class="p-button-rounded p-button-danger" v-else />
                        </template>
                    </Column>
                    <Column header="Aksi" headerStyle="min-width:10rem;">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                                @click="editItem(slotProps.data)" v-if="permission.includes('update')" />
                        </template>
                    </Column>
                    <template #empty>
                        No data found.
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template>
                    <template #footer>
                        In total there are {{ totalItemsCount ? totalItemsCount : 0 }} data.
                    </template>
                </DataTable>

                <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount"
                    :rowsPerPageOptions="[10, 20, 30]">
                    <template>
                        {{ totalItemsCount }}
                    </template>
                </Paginator>

                <Edit @submit="getDataTable()" :item="item" ref="edit" />
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import { numberingDatatable } from '../../utils/helpers';
import Create from '../../components/master/pjp/Create.vue';
import Import from '../../components/master/pjp/Import.vue';
import Edit from '../../components/master/pjp/Edit.vue';
import Error from '../../components/Error.vue';

export default {
    components: {
        'Create': Create,
        'Import': Import,
        'Edit': Edit,
        'Error': Error,
    },
    data() {
        return {
            // loading
            loading: false,
            loadingExcel: false,
            loadingCsv: false,
            loadingDropdownRegion: false,
            loadingDropdownDistributor: false,

            // edit,reset
            item: {},

            // dataDropdown
            dataDropdownRegion: null,
            dataDropdownDistributor: null,

            // filter
            filters: {
                region_id: null,
                dist_code: null,
            },

            //datatables
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,
        }
    },
    mounted() {
        this.getDataTable();
        this.$store.commit('setErrors', {});
        this.searchDropdownRegion('');
        this.searchDropdownDistributor('');
    },
    watch: {
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        }
    },
    computed: {
        ...mapGetters(['errors']),
        ...mapGetters("auth", ["permission"]),
    },
    methods: {
        formatDate(value) {
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },
        // EDIT
        editItem(item) {
            this.item = item;

            this.$refs.edit.editItemDialog = true;
        },
        searchDropdownRegion(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.drgn.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownRegion = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-region-v2',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                    .then(res => {

                        if (purpose == "filter") {
                            this.dataDropdownRegion = res.data.data;
                            this.loadingDropdownRegion = false;
                        } else if (purpose == null) {
                            this.dataDropdownRegion = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        changeRegion(purpose){
            if(purpose == 'filter'){
                this.filters.dist_code= null;
                this.dataDropdownDistributor= null;
                this.searchDropdownDistributor('','filter');
            }
        },
        searchDropdownDistributor(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.drgn.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownDistirbutor = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-distributor-v2',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                        "region_id" : this.filters.region_id,
                    }
                })
                    .then(res => {

                        if (purpose == "filter") {
                            this.dataDropdownDistributor = res.data.data;
                            this.loadingDropdownDistributor = false;
                        } else if (purpose == null) {
                            this.dataDropdownDistributor = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        // DATATABLE
        getDataTable() {

            this.loading = true;
            // this.$refs.filter.loading = true;
            this.page = (this.offset / this.rows) + 1;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/master-pjp',
                params: {
                    "region_id" : this.filters.region_id,
                    "dist_code": this.filters.dist_code,
                    "search": this.search,
                    "per_page": this.rows,
                    "page": this.page,
                    "order_by": this.field,
                    "sort_by": this.sort,
                }
            })
                .then(res => {
                    this.data = res.data.data.data;
                    this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
                    this.totalItemsCount = res.data.data.total;
                    this.rows = parseInt(res.data.data.per_page);
                    this.loading = false;
                    // this.$refs.filter.loading = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.loading = false;
                    // this.$refs.filter.loading = false;
                });

        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
        // EXPORT
        exportExcelCSV(ext) {

            if (ext == 'xlsx') {
                this.loadingExcel = true;
            } else if (ext == 'csv') {
                this.loadingCsv = true;
            }

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/master-pjp/export',
                responseType: 'blob',
                params: {
                    "ext": ext,
                }
            })
                .then(response => {
                    let fileUrl = window.URL.createObjectURL(response.data);
                    let fileLink = document.createElement('a');

                    fileLink.href = fileUrl;

                    fileLink.setAttribute('download', 'PJP Export.' + ext);

                    document.body.appendChild(fileLink)

                    fileLink.click();

                    if (ext == 'xlsx') {
                        this.loadingExcel = false;
                    } else if (ext == 'csv') {
                        this.loadingCsv = false;
                    }

                })
                .catch((err) => {
                    console.log(err);

                    if (ext == 'xlsx') {
                        this.loadingExcel = false;
                    } else if (ext == 'csv') {
                        this.loadingCsv = false;
                    }
                });
        },
    }
}
</script>