<template>
    <div class="p-fluid mb-3">       
        <div class="grid">
            <div class="lg:col-5 md:col-12 sm:col-12 align-items-center justify-content-center">
                <h5>Panduan</h5>
                <ol>
                    <li>
                        <p class="line-height-3 m-0">Silahkan download template terlebih dahulu <a target="_blank" href="https://docs.google.com/spreadsheets/d/1LRP0epaHcLxPXWj19UD3aqaVrDANPfQK/edit#gid=226072006"><u><i>DISINI</i></u></a> </p>
                    </li>
                    <li>
                        <p class="line-height-3 m-0">Pastikan file upload excel atau csv</p>
                    </li>
                    <li>
                        <p class="line-height-3 m-0">Pastikan ukuran file tidak lebih dari 2MB</p>
                    </li>
                    <li>
                        <p class="line-height-3 m-0">Pastikan kolom terisi dengan benar</p>
                    </li>
                </ol>
                <div class="line-height-3 m-0" style="color:red;">Note: harap ikuti petunjuk langkah demi langkah dengan cermat untuk memastikan data diunggah dengan sukses.</div>
                <!-- <h5>Download Template</h5>
                <div class="p-fluid">
                    <div class="formgrid grid">
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <div class="field mt-2 ml-2">
                                <Button :loading="loadingDownloadTemplate" label="Download" icon="pi pi-download" class="p-button-success my-1" @click="downloadTemplate('xlsx')" />
                                <Button :loading="loadingDownloadTemplate" label="Download" icon="pi pi-download" class="p-button-success my-1" @click="downloadTemplateStatis()" />
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
            
            <div class="lg:col-1">
                <Divider layout="vertical">
                    <!-- <b>OR</b> -->
                </Divider>
            </div>

            <div class="sm:col-12 md:col-12 lg:col-6">
                <BlockUI :blocked="blockPanel">
                    <form>
                        <div class="p-fluid">
                            <div class="formgrid grid">
                                <div class="field col-12 md:col-12 lg:col-6">
                                    <!-- FORM -->
                                    <label>Pilih Tujuan</label>
                                    <Dropdown v-model="forms.option" :options="dataDropdownOption" optionLabel="name" optionValue="code" placeholder="Pilih Tujuan"/>
                                </div>
                            </div>
                        </div>
                        <FileUpload ref="fileUpload" name="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" chooseLabel="Pilih File" uploadLabel="Unggah" cancelLabel="Batal" :customUpload="true" @uploader="onUpload" :fileLimit="1" :maxFileSize="2000000">
                        <template #empty>
                            <p>Seret dan taruh file disini untuk mengunggah.</p>
                            <ProgressBar mode="indeterminate" style="height: .5em" v-if="blockPanel"/>
                        </template>
                        </FileUpload>
                    </form>
                </BlockUI>
            </div>
        </div>
    </div>
</template>

<script>

import { mapGetters } from "vuex";

export default {
    data() {
        return {
            // loading
            loadingDownloadTemplate: false,

            // upload
            blockPanel: false,
            file: null,
            forms: {
                option: "TAMBAH",
            },

            // dataDropdown
            dataDropdownOption: [
                {name: 'Tambah dan Update', code: 'TAMBAH'},
                {name: 'Hapus dan Tambah', code: 'GANTI'},
            ],
        }
    },
    mounted() {
        this.$store.commit('setErrors', {});
    },
    computed: {
        ...mapGetters(['errors']),
    },
    methods: {
        // DOWNLOADTEMPLATE
        // downloadTemplate(ext){
        //     this.loadingDownloadTemplate= true;

        //     this.axios({
        //         method: 'GET',
        //         url: process.env.VUE_APP_ROOT_API + 'web/master-outlet/template-upload',
        //         responseType: 'blob',
        //         params: {
        //             "ext" : ext,
        //         }
        //     })
        //     .then(response => {
        //         let fileUrl = window.URL.createObjectURL(response.data);
        //         let fileLink = document.createElement('a');

        //         fileLink.href = fileUrl;

        //         fileLink.setAttribute('download', 'Import Master Outlet Template.' + ext);
                
        //         document.body.appendChild(fileLink)

        //         fileLink.click();

        //         this.loadingDownloadTemplate= false;

        //     })
        //     .catch((err) => {
        //         console.log(err);

        //         this.loadingDownloadTemplate= false;
        //     });
        // },
        // downloadTemplateStatis(){
        //     this.loadingDownloadTemplate= true;

        //     const url = 'template-upload/template_master_outlet.xlsx';
        //     window.location.href = url;

        //     this.loadingDownloadTemplate= false;
        // },
        //UPLOAD
        onUpload(event) {

            this.$store.commit("setErrors", {});
            this.blockPanel = true;
            this.file = event.files[0];

            let data = new FormData();
            data.append('file', this.file);
            data.append('tipe', this.forms.option);

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/master-pjp/upload',
                data: data,
                onUploadProgress: function (e) {
                     this.$refs.fileUpload.progress = parseInt(Math.round((e.loaded * 100) / e.total));
                }.bind(this)
            })
			.then(res => {
                console.log(res);
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Data Berhasil Disimpan', life: 3000});
                this.$store.commit("setErrors", {});
                this.$refs.fileUpload.uploadedFileCount = 0;
                this.$refs.fileUpload.progress = null;
                this.blockPanel = false;

                this.clearForms();

                this.$emit('submit');
			})
            .catch((err) => {
                console.log(err);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Disimpan', life: 3000,});
                this.$refs.fileUpload.uploadedFileCount = 0;
                this.$refs.fileUpload.progress = null;
                // this.$refs.message.visible = true;
                this.blockPanel = false;
            });
        },
        clearForms() {
            this.file = null;
            this.forms.option = "TAMBAH";
        },
    }
}
</script>